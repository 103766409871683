<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>
            <div class="headline">Cross Point プライバシーポリシー</div>
          </v-card-title>
          <v-card-subtitle>
            <div class="subtitle-2 font-weight-bold">
              Last updated: {{ lastUpdateDate }}
            </div>
          </v-card-subtitle>
          <v-card-text>
            <p>
              リディッシュ株式会社（以下「当社」といいます。）は、個人情報を大切に保護することを企業の重要な社会的使命と認識し、役員はじめ全従業者が個人情報保護に関する法規範を遵守し、次に示す当社基本方針を具現化し全社を挙げて取り組むこと誓います。
            </p>

            <p>
              1 法令などの遵守について<br />
              当社は、個人情報保護の実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を遵守いたします。
            </p>

            <p>
              2 個人情報の収集方法とその目的について<br />
              当社はCross
              Point内で、お客様の個人情報を収集する場合があります。<br />
              当社は、個人情報の取り扱いについて業務実態に応じた個人情報保護のための管理体制を確立するとともに、当該情報を以下の目的の範囲内で社内規程等に従い適切かつ慎重に使用します。
            </p>
            <p>
              ・当社の提供するサービス（以下「本サービス」といいます。）の提供及び管理のため<br />
              ・お客様の本サービスの利用状況、稼働状況を案内するため<br />
              ・本サービスの案内のため 本サービスの改善及び開発のため<br />
              ・問い合わせ対応のため アンケートの実施のため<br />
              ・お客様に合わせてカスタマイズしたコンテンツを提供するため<br />
              ・お客様に本サービスや当社に関するお知らせを送信するため<br />
              ・マーケティング及びキャンペーンでの利用のため<br />
              ・個人又は特定の利用者を識別できない形式に加工した、本サービスの利用状況に関する統計データを作成するため<br />
              ・その他前各項目に付帯・関連する事項のため<br />
              なお、本アプリから取得した個人情報を当社が取得・保有する他の情報で補足する場合があります。本アプリで収集した個人情報を収集当初の目的の範囲を超えて利用することはありません。<br />
              また当社が個人情報を第三者から間接的に取得する場合は、当該第三者が本人から適正に取得したものであるかどうかを確認した上で、上記目的の範囲内で利用します。
            </p>

            <p>
              3 個人情報の第三者への提供について<br />
              当社は、お預かりした個人情報をお客様からの事前の同意を得ることなく第三者に提供することは行いません。ただし、以下の場合は除きます。
            </p>
            <p>
              ・当初の利用目的の範囲内で外部の委託先への個人情報の提供が必要になった場合<br />
              なお、委託先への個人情報の提供が必要になった際には、委託先に対し情報管理に関する適切な監督を行ったうえで提供を行います。<br />
              ・個人情報保護法に基づき許容される場合
            </p>

            <p>
              4 ログファイル・クッキー・ウェブビーコン等の技術的情報について<br />
              本アプリでは、ログファイルの収集、クッキーの送信（広告の効果測定を目的とした第三者サーバーからのクッキー送信を含む）、ウェブビーコンの設置などによりお客様のIPアドレス、アクセス回数、ご利用ブラウザ、OSその他利用端末などの情報を収集しています。その他、アプリからご利用の端末のID、wi-fi接続に関する情報も取得致します。これらの情報は、本ウェブサイト及び本アプリの利用傾向の分析や問題発生時の原因究明を目的に収集しており、個人を特定する目的の利用はしておりません。
            </p>
            <p>
              5
              個人情報に関する本人からの開示、訂正、利用停止等の方法について<br />
              お客様は、個人情報保護法に従い、お客様ご本人の個人情報の開示、訂正、利用停止等を請求することができます。個人情報の開示、訂正、利用停止等のご請求、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、以下のメールアドレス宛にご連絡頂くか、または本サービスログイン後の「設定・その他」で自ら修正・確認を行うことが出来ます。ご本人であることを確認したうえで対応致します。<br />
              mail@redish.jp
            </p>
            <p>
              6 開示に対する法的免責事項について<br />
              法律の定めにより、国、地方自治体、裁判所、警察その他法律や条例などで認められた権限を持つ機関より要請があった場合には、これに応じてお客様の同意なく情報を開示することがあります。
            </p>
            <p>
              7 従業員及び委託先の監督について<br />
              当社では、セキュリティや個人情報保護に関する社内教育等を定期的に実施し、従業員の個人情報保護への意識の向上、啓発に努めています。また、当社は、各種業務において、個人情報の取扱いの全部又は一部を委託する場合があります。この場合、当社は、個人情報を適正に取り扱うと認められるものを選定し、委託契約において、安全管理措置、秘密保持、再委託の条件その他の個人情報の取扱いに関する事項について適正に定め、必要かつ適切な監督を実施します。
            </p>
            <p>
              8 セキュリティ対策について<br />
              当社は、収集した個人情報保護のために合理的な予防措置を講じ、オンライン・オフラインの両方において保護を行います。ファイヤウォール、不正侵入検知、SSLなどの技術を用いて個人情報の取り扱いには細心の注意を図ると共に、業務上必要とされる権限を与えられた者のみがアクセス可能としています。
            </p>
            <p>
              9 収集当初の目的と異なった利用に該当する場合について<br />
              当社では、原則として、収集時点のプライバシーポリシーに記載されている利用目的の範囲を超えてお客様の個人情報を利用することはありません。当初の利用目的の範囲を超えて個人情報を利用することになった際には、事前にお客様の同意を得たうえで行います。
            </p>
            <p>
              10 リンク先の個人情報の取扱いについて<br />
              本アプリには外部のリンクが複数存在します。本プライバシーポリシーの適用範囲は本ウェブサイト及び本アプリのみとなりますので、外部のウェブサイト等での個人情報の取扱についてはリンク先ウェブサイトのプライバシーポリシーをご参照ください。
            </p>
            <p>
              11 プライバシーポリシーの変更・更新通知及び苦情について<br />
              当社は、本サービスを定期的に見直し、改善を行います。それに伴い、プライバシーポリシーを変更する場合があります。プライバシーポリシーに重要な変更がある場合には、本ウェブサイト上で告知します。また当社の個人情報の取扱に関してのお問合わせ・苦情等は以下の連絡先までご連絡ください。
            </p>
            <p>
              リディッシュ株式会社 東京都港区六本木西麻布1-12-5 SW
              六本木通ビル3F<br />
              mail@redish.jp
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "PrivacyPolicyPage",
  data() {
    return {
      lastUpdateDate: dayjs().format(" YYYY年 MM月 DD日")
    };
  }
};
</script>

<style scoped></style>
